<template>
  <section class="section-wrapper container" :style="{
    backgroundImage: `url(${phonesImageSrc})`,
  }">
    <div class="section">
      <h2 class="section__title">Crypto Insights</h2>
      <div class="section__text">
      <p>Crypto Insights is your all-in-one solution for navigating the complex world of cryptocurrencies with ease and confidence. Whether you're a seasoned investor or just dipping your toes into the exciting realm of digital assets, Crypto Insights has everything you need to stay informed, make informed decisions, and manage your crypto portfolio effectively.</p>
      <br/>
      <p>Your Essential Cryptocurrency App. Download and stay ahead in the crypto game!</p>
      </div>
    </div>
    <div class="flex-spacer"></div>
    <h3 class="section__download-title">Download this app</h3>
    <div class="section__links-wrapper">
      <a :href="appStoreLink" class="link" target="_blank" rel="nofollow noopener noreferrer">
        <img :src="appStoreIconSrc" width="235" alt="Download on the App Store" />
      </a>
      <a :href="googlePlayLink" class="link" target="_blank" rel="nofollow noopener noreferrer">
        <img :src="googlePlayIconSrc" width="235" alt="Get it on Google Play" />
      </a>
    </div>
    <!-- <img
      class="phones-image"
      :src="phonesImageSrc"
      width="550"
      alt="Crypto Insights mobile app"
    /> -->

    <!-- <div class="scroll-down-btn" @click="scrollToCoinsListing">
      <div class="icon"></div>
    </div> -->
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

const appStoreLink =
  'https://apps.apple.com/us/app/crypto-insights/id1586606381';

const googlePlayLink =
  'https://play.google.com/store/apps/details?id=com.softpoint.cryptoinsights';

export default {
  name: 'GreetingSection',
  data() {
    return {
      appStoreLink,
      googlePlayLink,
      appStoreIconSrc: require('@/assets/images/market-icons/app-store-icon.svg'),
      googlePlayIconSrc: require('@/assets/images/market-icons/google-play-icon.svg'),
    };
  },
  computed: {
    ...mapGetters({
      themeLight: 'user/themeLight',
    }),
    phonesImageSrc() {
      return require(`@/assets/images/home-page/phones-${this.themeLight ? 'light' : 'dark'
        }${window && window.devicePixelRatio > 1 ? '@2x' : ''}.png`);
    },
  },
  beforeMount() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      location.href = googlePlayLink;
    }
    else if (/iPhone OS/i.test(userAgent)) {
      location.href = appStoreLink;
    }
  },
  // methods: {
  //   scrollToCoinsListing() {
  //     const element = document.getElementById('coins-listing');
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  padding-top: 3vh;
  padding-bottom: max(40vh, 48vw);
  min-height: min(calc(100vh - var(--app-bar-height)), 90vw);
  position: relative;
  display: flex;
  flex-direction: column;
  background-position: center bottom 2vh;
  background-size: max(28vh, 35vw) auto;
  background-repeat: no-repeat;
  transition: background-image var(--btn-hover-transition);

  @include respond-to('md') {
    padding-top: 4vh;
    padding-bottom: max(4vh, 10vw);
    background-position: right var(--container-side-padding) top 5vh;
    background-size: min(60vh, 37%) auto;
  }

  @include respond-to('lg') {}

  @include respond-to('xl') {
    padding-top: 12vh;
    padding-bottom: 12vh;
    background-position: right var(--container-side-padding) top 40%;
  }

  .section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include respond-to('md') {
      max-width: 57%;
    }

    @include respond-to('lg') {
      max-width: 48%;
    }

    &__text {
      margin-bottom: 19vh;

      @include respond-to('md') {
        margin-bottom: 40px;
      }
    }

    &__title,
    &__download-title {
      margin-bottom: 20px;
      font-family: var(--secondary-font-family);
      line-height: 1.22;
      font-weight: 400;
    }

    &__title {
      font-size: 50px;

      @include respond-to('lg') {
        font-size: 58px;
      }
    }

    &__download-title {
      font-size: 32px;

      @include respond-to('lg') {
        font-size: 40px;
      }
    }

    &__links-wrapper {
      display: flex;
      align-items: center;

      .link {
        &:not(:last-child) {
          margin-right: var(--container-side-padding);
        }
      }
    }
  }

  // .scroll-down-btn {
  //   display: none;
  //   position: absolute;
  //   bottom: 48px;
  //   left: 50%;
  //   margin-left: -12px;
  //   padding: 10px;
  //   border-radius: 50%;
  //   transform: rotate(-45deg);
  //   // opacity: 0.6;
  //   animation: scroll-animation 2s infinite;
  //   // transition: opacity var(--btn-hover-transition);
  //   cursor: pointer;

  //   // &:hover {
  //   //   opacity: 1;
  //   // }

  //   @include respond-to('md') {
  //     display: block;
  //   }

  //   @include respond-to('lg') {
  //     bottom: 60px;
  //   }

  //   .icon {
  //     width: 24px;
  //     height: 24px;
  //     border-left: 1px solid var(--primary-font-color);
  //     border-bottom: 1px solid var(--primary-font-color);
  //   }

  //   @keyframes scroll-animation {
  //     0% {
  //       transform: rotate(-45deg) translate(0, 0);
  //       opacity: 0;
  //     }
  //     50% {
  //       opacity: 1;
  //     }
  //     100% {
  //       transform: rotate(-45deg) translate(-20px, 20px);
  //       opacity: 0;
  //     }
  //   }
  // }
}
</style>
