<template>
  <div>
    <GreetingSection />
    <!-- <CoinsListingTable /> -->
  </div>
</template>

<script>
import GreetingSection from '@/components/HomePage/GreetingSection.vue';
// import CoinsListingTable from '@/components/HomePage/CoinsListingTable.vue';

export default {
  name: 'HomeView',
  components: {
    GreetingSection,
    // CoinsListingTable,
  },
  metaInfo() {
    return {
      titleTemplate: 'Crypto Insights',
    };
  },
};
</script>
